import React from "react"
import styled from "styled-components"
import AnimatedImageComp from "../global/AnimatedImage"
import FlexImageText from "../global/FlexImageText"
import parse from "html-react-parser"
import { GatsbyImage } from "gatsby-plugin-image"

const Wrapper = styled.section`
  padding: 0;
  position: relative;
  background-color: var(--white);
  z-index: 10;
  overflow: hidden;
  /* border-top: 100px solid var(--charcoal); */

  .main-heading {
    text-align: center;
    text-transform: uppercase;
    font-family: var(--font-bold);
    background: var(--brandColor);
    color: var(--white);
    padding: 8rem 0;
    margin-bottom: 4rem;
    position: relative;

    h1 {
      margin: 0;
      padding: 0;
      font-size: 6rem;
      position: relative;
      z-index: 10;
      display: block;
    }

    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 0;
    }

    &.multiplyBG {
      .gatsby-image-wrapper {
        mix-blend-mode: multiply;

        img {
          filter: grayscale(1);
        }
      }
    }
    &.luminosityBG {
      .gatsby-image-wrapper {
        mix-blend-mode: luminosity;
      }
    }
  }

  .image-wrapper {
    max-width: 800px;
    margin: 0 auto 4rem auto;

    @media only screen and (min-width: 768px) {
      margin: 0 auto 8rem auto;
    }
  }
  .text-wrapper {
    max-width: 1024px;
    position: relative;
    margin: 0 auto 4rem auto;
    padding: 0 2rem;

    @media only screen and (min-width: 768px) {
      margin: 0 auto 8rem auto;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      color: var(--black);
      font-family: var(--font-bold);
      margin-bottom: 2rem;
    }

    ul li,
    p {
      line-height: 1.5rem;
      margin-bottom: 1rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      color: var(--black);
      font-family: var(--font-bold);
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  section:last-child {
    margin-bottom: 0;
  }

  section:last-child .image-wrapper {
    margin-bottom: 0;
  }
  /* section:last-child * {
    margin-bottom: 0;
  } */
`

export default function Editorial({ editorial, area, areaImage }) {
  // console.log(editorial)
  return (
    <Wrapper>
      <div
        className={`main-heading ${
          process.env.GATSBY_SUBDOMAIN === "york"
            ? "multiplyBG"
            : "luminosityBG"
        }`}
      >
        <h1>{area}</h1>
        <GatsbyImage
          image={areaImage.image.localFile.childImageSharp.gatsbyImageData}
        />
      </div>
      {editorial.map(flex => {
        if (flex.fieldGroupName === "page_AcfAreas_Editorial_ImageText") {
          return (
            <FlexImageText
              lessMarginBottom={true}
              alignment={"tlir"}
              backgroundColour={"yellow"}
              buttonLink={null}
              buttonText={null}
              buttonType={null}
              heading={null}
              snipcart={null}
              text={flex.imageText.text}
              url={null}
              image={flex.imageText.image}
              buttonPageLink={null}
              finalButtonPageLinkIgnore={null}
            />
          )
        }
        if (flex.fieldGroupName === "page_AcfAreas_Editorial_Image") {
          {
            /* console.log(flex.image) */
          }
          return (
            <section className="image-wrapper">
              <AnimatedImageComp
                imgSrc={flex.image.localFile?.childImageSharp?.gatsbyImageData}
                offsetX={"0"}
                offsetY={"0"}
                color={"brandColor"}
              />
            </section>
          )
        }
        if (flex.fieldGroupName === "page_AcfAreas_Editorial_Text") {
          return <section className="text-wrapper">{parse(flex.text)}</section>
        }
      })}
    </Wrapper>
  )
}
