import React, { useRef, useEffect, useState } from "react"
import GoogleMapReact from "google-map-react"
import MarkerClusterer from "@googlemaps/markerclustererplus"
import MapMarker from "../../images/map-marker.svg"
import MapMarkerHover from "../../images/map-marker-hover.svg"
import MapMarkerCluster from "../../images/map-marker-cluster.svg"
import MapMarkerClusterDarker from "../../images/map-marker-cluster-darker.svg"
import InfoWindowTriangle from "../../images/info-window-triangle.svg"
import MapMarkerPremiumLeeds from "../../images/map-marker-premium.svg"
import MapMarkerPremiumYork from "../../images/map-marker-premium-york.svg"
import MapMarkerPremiumManchester from "../../images/map-marker-premium-manchester.svg"
import MapMarkerPremiumHover from "../../images/map-marker-premium-hover.svg"
import { IoIosContacts } from "react-icons/io"
import { GrSort } from "react-icons/gr"
import DD from "../../assets/dd.inline.svg"
import styled from "styled-components"
import Button from "../global/Button"
import { GatsbyImage } from "gatsby-plugin-image"
import mapStyles from "../../styles/map-styles.json"
import Listing from "./Listing"

// import MapListingDetail from './MapListingDetail';
import { IoIosCloseCircleOutline } from "react-icons/io"
import getListingSlug from "../../utils/getListingSlug"
import { Link } from "gatsby"

const IndependentsMapAndFilter = styled.div`
  width: 100vw;
  height: calc(100vh - var(--headerHeight));
  height: calc((var(--vh, 1vh) * 100) - var(--headerHeight));
  display: block;
  background: var(--brandColor);
  position: relative;
  overflow: hidden;

  @media only screen and (min-width: 1024px) {
    display: flex;
  }

  #listings-scroller {
    @media only screen and (max-width: 1024px) {
      pointer-events: none;
    }

    &.showListings {
      pointer-events: all;
    }
  }
`
const User = styled.div`
  transform: translate(-50%, -50%);
  background-color: rgba(218, 54, 73, 1);
  border-radius: 50%;
  width: 20px;
  height: 20px;
  animation: shine 2s ease-in-out infinite;
  position: fixed;

  @keyframes shine {
    0%,
    20% {
      box-shadow: 0px 0px 0px 0px rgba(218, 54, 73, 1);
    }
    100% {
      box-shadow: 0px 0px 0px 50px rgba(0, 0, 0, 0);
    }
  }
`

const ToggleWrap = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 43px;
  display: flex;
  background: var(--brandColor);
  z-index: 20;
  transition: 0.5s all ease-in-out;

  @media only screen and (min-width: 1024px) {
    display: none;
  }

  .button {
    text-align: center;
    width: 50%;
    cursor: pointer;
    transition: 0.5s all ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px solid var(--black);

    &.active,
    &:hover {
      background: var(--black);
      color: var(--brandColor);
      &:first-child {
        svg path {
          stroke: var(--brandColor);
        }
      }
      &:nth-child(2) {
        svg path {
          fill: var(--brandColor);
        }
      }
    }

    svg {
      margin-right: 0.5rem;
      margin-top: -4px;
    }

    svg path {
      transition: 0.5s all ease-in-out;
    }

    span {
      margin-right: 4px;
    }

    &:first-child {
      border-right: 1px solid var(--black);
    }
  }

  &.error {
    background: var(--red);

    .button {
      &.active,
      &:hover {
        background: var(--black);
        color: var(--red);
        &:first-child {
          svg path {
            stroke: var(--red);
          }
        }
        &:nth-child(2) {
          svg path {
            fill: var(--red);
          }
        }
      }
    }
  }
`

const MapWrap = styled.div`
  position: relative;
  background: var(--black);
  width: 100vw;
  /* height:calc(100% - var(--headerHeight)); */
  height: calc(100vh - var(--headerHeight) - 43px);
  min-height: calc((var(--vh, 1vh) * 100) - var(--headerHeight) - 43px);

  @media only screen and (min-width: 1024px) {
    height: 100%;
    width: 60vw;
  }

  *:focus,
  *::focus {
    outline: none !important;
  }

  /* ----------------------------------------------
 * Generated by Animista on 2021-4-8 16:46:56
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

  /**
 * ----------------------------------------
 * animation bounce-top
 * ----------------------------------------
 */
  @-webkit-keyframes bounce-top {
    0% {
      -webkit-transform: translateY(-45px);
      transform: translateY(-45px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
      opacity: 1;
    }
    24% {
      opacity: 1;
    }
    40% {
      -webkit-transform: translateY(-24px);
      transform: translateY(-24px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    65% {
      -webkit-transform: translateY(-12px);
      transform: translateY(-12px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    82% {
      -webkit-transform: translateY(-6px);
      transform: translateY(-6px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    93% {
      -webkit-transform: translateY(-4px);
      transform: translateY(-4px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    25%,
    55%,
    75%,
    87% {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
    100% {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
      opacity: 1;
    }
  }
  @keyframes bounce-top {
    0% {
      -webkit-transform: translateY(-45px);
      transform: translateY(-45px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
      opacity: 1;
    }
    24% {
      opacity: 1;
    }
    40% {
      -webkit-transform: translateY(-24px);
      transform: translateY(-24px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    65% {
      -webkit-transform: translateY(-12px);
      transform: translateY(-12px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    82% {
      -webkit-transform: translateY(-6px);
      transform: translateY(-6px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    93% {
      -webkit-transform: translateY(-4px);
      transform: translateY(-4px);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
    25%,
    55%,
    75%,
    87% {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
    }
    100% {
      -webkit-transform: translateY(0px);
      transform: translateY(0px);
      -webkit-animation-timing-function: ease-out;
      animation-timing-function: ease-out;
      opacity: 1;
    }
  }

  .map-loading {
    width: 100%;
    height: 100%;
    font-family: var(--font-heading);
    font-size: 4rem;
    letter-spacing: 2px;
    text-transform: uppercase;
    transition: 0.5s all ease-in-out;
    color: var(--${process.env.GATSBY_BRANDCOLOR});
    display: flex;
    justify-content: center;
    align-items: center;

    /* .beacon{
        margin-left: 20px;
        margin-top: 20px;
        } */

    span {
      /* margin-top:100px; */
      justify-content: center;
      align-items: center;
      -webkit-animation: bounce-top 0.9s both;
      animation: bounce-top 0.9s both;
    }
  }

  .map-wrap-inner {
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: 0.5s all ease-in-out;
  }

  &.loaded {
    .map-wrap-inner {
      opacity: 1;
    }
    .map-loading {
      opacity: 0;
    }
  }
`

const ErrorWarning = styled.div`
  padding: 0 1rem;
  line-height: 1.5rem;

  &.error {
    color: var(--white);
  }
`

const FilterWrap = styled.div`
  transition: 0.5s all ease-in-out;
  /* padding:2rem 2rem 2rem 4rem; */
  width: 100vw;
  position: absolute;
  bottom: 0;
  height: 222px;
  transform: translateY(100%);

  &.showFilters {
    transform: translateY(-43px);
  }

  @media only screen and (min-width: 1024px) {
    transform: translateY(0);
    position: relative;
    width: 40vw;
    right: 0;
    /* top:7rem; */
    overflow: scroll;
    height: calc(100vh - var(--headerHeight));
    height: calc((var(--vh, 1vh) * 100) - var(--headerHeight));
  }

  &:before {
    content: "";
    width: 40vw;
    height: 100%;
    background: var(--brandColor);
    top: 0;
    right: 0;
    z-index: 0;
  }

  .filters--wrapper {
    position: absolute;
    z-index: 1;
    overflow: hidden;
    padding: 2rem 1rem 1rem 1rem;
    /* background:var(--brandColor); */
    transition: 0.5s all ease-in-out;
    width: 100%;

    @media only screen and (min-width: 1024px) {
      width: 40vw;
    }

    &.error {
      background: var(--red);
      color: var(--white);

      select,
      .clear,
      .offers span {
        color: var(--white);
      }

      .dd polygon {
        fill: var(--white);
      }

      select,
      .offers {
        border-bottom: 1px solid var(--white);
      }

      select,
      .offers {
        &:hover {
          background: var(--white);
          color: var(--red);
          span {
            color: var(--red);
          }
        }
      }

      .offers.checked {
        background: var(--white);
        span {
          color: var(--red);
        }
      }
    }

    .filters-inner-wrapper.hide_locations {
      .select-wrap,
      .offers {
        width: calc(50% - 1rem);
      }
    }
  }

  .heading {
    font-family: var(--font-bold);
    font-size: 1.4rem;
    margin-bottom: 1rem;
    z-index: 1;
    position: relative;
  }

  .clear {
    position: absolute;
    top: 2rem;
    right: 1rem;
    background: none;
    border: none;
    font-size: 0.8rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    z-index: 10;
    padding: 0.25rem;
    border-radius: 5px;
    transition: 0.5s all ease-in-out;
    opacity: 1;
    transform: translateX(0%);

    &.hidden {
      opacity: 0;
      transform: translateX(100%);
    }

    &:focus {
      outline: none;
    }

    &:hover {
      background: var(--black);
      color: var(--brandColor);
    }

    svg {
      position: relative;
      width: 20px;
      height: 20px;
      margin-left: 3px;
      top: -1px;

      /* path:nth-child(1){
                fill:pink;
            }
            path:nth-child(2){
                fill:pink;
            } */
    }
  }

  .filters-inner-wrapper {
    width: calc(100% + 1rem);
  }

  .select-wrap {
    position: relative;
    display: inline-block;
    width: calc(100% - 1rem);
    margin-bottom: 1rem;

    @media only screen and (min-width: 1024px) {
      width: calc(33.33% - 1rem);
      margin-right: 1rem;
      margin-bottom: 0;
    }

    .dd {
      position: absolute;
      width: 10px;
      height: 100%;
      right: 0.5rem;
      pointer-events: none;

      polygon {
        fill: var(--black);
        transition: 0.5s all ease-in-out;
      }
    }

    &:hover {
      .dd {
        polygon {
          fill: var(--brandColor);
        }
      }
    }
  }

  select {
    width: 100%;
    background: none;
    border: 0;
    font-family: var(--font-bold);
    padding: 0.5rem;
    border-bottom: 1px solid var(--black);
    cursor: pointer;
    transition: 0.5s all ease-in-out;
    border-radius: 5px 5px 0 0;

    &:hover {
      background: var(--black);
      color: var(--brandColor);
    }
  }

  .offers {
    position: relative;
    font-family: var(--font-bold);
    border: none;
    border-bottom: 1px solid var(--black);
    display: inline-block;
    cursor: pointer;
    padding: 0.5rem;
    background: none;
    text-align: left;
    transition: 0.5s all ease-in-out;
    border-radius: 5px 5px 0 0;
    width: calc(33.33% - 1rem);
    width: calc(100% - 1rem);
    color: var(--black);
    text-decoration: none;

    @media only screen and (min-width: 1024px) {
      width: calc(33.33% - 1rem);
      margin-right: 1rem;
    }

    &:focus {
      outline: none;
    }

    &:hover {
      background: var(--black);
      color: var(--brandColor);
    }

    &.checked {
      background: var(--black);
      color: var(--brandColor);
    }

    span {
      pointer-events: none;
    }

    input[type="checkbox"] {
      width: 100%;
      position: absolute;
      left: 0;
      /* visibility:hidden; */
    }
  }

  select {
    &:focus {
      outline: none;
    }
  }
`

const CurrentlyViewing = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`

const ListingsContainer = styled.div`
  .listing {
    &:last-child {
      /* margin-bottom:12rem; */
      margin-bottom: 8rem;

      @media only screen and (min-width: 1024px) {
        margin-bottom: 1rem;
      }
    }
  }
`

const ListingWrapper = styled.div`
  .infowindow {
    position: fixed;
    left: 0;
    top: 0;
    z-index: -10;
  }
`

const ListingsWrap = styled.div`
  z-index: 0;
  overflow: scroll;
  position: fixed;
  top: 0;
  right: 0;
  top: 0;
  right: 0;
  height: 100vh;
  background: var(--brandColor);
  width: 100vw;
  scroll-behavior: smooth;
  padding: 1rem 0;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  transform: translateY(100%);
  transition: 0.5s all ease-in-out;
  /* pointer-events: none; */

  @media only screen and (min-width: 1024px) {
    transform: translateY(0);
  }

  &.error {
    background: var(--red);
    color: var(--white);

    &:before {
      opacity: 0;
    }
  }

  ::-webkit-scrollbar {
    width: 0; /* Remove scrollbar space */
    background: transparent; /* Optional: just make scrollbar invisible */
  }

  &.showListings {
    transform: translateY(0);
    pointer-events: all;
  }

  &:before {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 0;
    background: var(--brandColor);
    content: "";
    opacity: 1;
    transition: 0.5s all ease-in-out;
  }

  @media only screen and (min-width: 1024px) {
    margin: 121px 0 0 0;
    width: 40vw;
    padding: 0;
    background: none;
    /* display:block; */
    overflow: scroll;
    height: calc(100vh - 241px);
    height: calc((var(--vh, 1vh) * 100 - 241px));
    max-height: calc(100vh - 241px);
    max-height: calc((var(--vh, 1vh) * 100 - 241px));
  }

  .heading {
    font-family: var(--font-bold);
    font-size: 1.4rem;
    margin-bottom: 1rem;
    z-index: 1;
    position: relative;
  }
`

const InfoWindow = styled.div`
  width: 260px;
  background: var(--grey);
  text-align: center;
  padding: 0;
  position: relative;
  transition: 0.5s all ease-in-out;

  &:after {
    width: 20px;
    content: "";
    height: 15px;
    background-image: url(${InfoWindowTriangle});
    background-size: 100% 100%;
    position: absolute;
    bottom: calc(0% - 30px);
    left: calc(50% - 10px);
  }

  .image {
    width: 100%;
    height: 100px;
    overflow: hidden;
    position: relative;

    .gatsby-image-wrapper {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;

      img {
        transition: 0.5s all ease-in-out !important;
      }
    }
  }

  .info {
    padding: 1rem;
    text-align: left;
    font-family: var(--font-reg);

    .heading {
      z-index: 0;
      font-family: var(--font-bold);
      font-size: 1.4rem;
    }
    .heading,
    .tagline,
    .offer {
      margin-bottom: 0.5rem;
      width: 100%;
      line-height: 1.25rem;
    }

    .tagline,
    .offer {
      font-size: 0.9rem;
    }

    .offer {
      font-family: var(--font-bold);
    }

    .address {
      font-size: 0.75rem;
    }
  }

  .btn-wrap {
    width: calc(100% - 2rem);
    margin-left: 1rem;
    margin-bottom: 1rem;

    div,
    a {
      width: 100%;
    }
  }
`

export default function MapFilters(props) {
  const {
    offersState,
    basicListings,
    premiumListings,
    allListings,
    offerListings,
    what,
    where,
    mapProp,
    area,
    showLocationsFilter,
  } = props

  const filter1 = useRef()
  const filter2 = useRef()
  const filterWrap = useRef()
  const listings = useRef()
  const mapRef = useRef()

  const [lat, setLat] = useState(
    parseFloat(
      mapProp?.acf_listing_location_fields?.lat ||
        mapProp?.options?.acf_options?.mapCentreLatitute
    )
  )
  const [lng, setLng] = useState(
    parseFloat(
      mapProp?.acf_listing_location_fields?.lng ||
        mapProp?.options?.acf_options?.mapCentreLongitude
    )
  )
  const [initialiseWhat, setInitialiseWhat] = useState(false)
  const [userLat, setUserLat] = useState(null)
  const [userLng, setUserLng] = useState(null)
  const [userConsent, setUserConsent] = useState(false)
  const [userPositionSet, setUserPositionSet] = useState(false)
  const [hasPanned, setHasPanned] = useState(false)
  const [zoom, setZoom] = useState(16)
  const [whatState, setWhatState] = useState("*")
  const [whereState, setWhereState] = useState("*")
  const [listingsRadius, setListingsRadius] = useState(200)
  const [allInboundListings, setAllInboundListings] = useState(null)
  const [markers, setMarkers] = useState([])
  const [maps, setMaps] = useState(null)
  const [map, setMap] = useState(null)
  const [items, setItems] = useState()
  const [mapLoaded, setMapLoaded] = useState(false)
  const [InfoWindowTitle, setInfoWindowTitle] = useState("test")
  const [InfoWindowImg, setInfoWindowImg] = useState(null)
  const [InfoWindowTagline, setInfoWindowTagline] = useState("test")
  const [InfoWindowOffer, setInfoWindowOffer] = useState("test")
  const [InfoWindowAddress, setInfoWindowAddress] = useState("test")
  const [InfoWindowSlug, setInfoWindowSlug] = useState("test")
  const [mapBounds, setMapBounds] = useState(null)
  const [filterActive, setFilterActive] = useState(false)
  const [listingsActive, setListingsActive] = useState(false)
  const [showResetBtn, setShowResetBtn] = useState()
  const [listingsWithActiveInfowindow, setListingsWithActiveInfowindow] =
    useState(null)

  const isClient = typeof window !== "undefined"

  const toggleFilters = e => {
    //console.log('togglefilters')
    if (listingsActive === true) {
      setFilterActive(!filterActive)
      setListingsActive(false)
    } else {
      setFilterActive(!filterActive)
    }
  }

  const toggleListings = e => {
    //console.log('toggleListings')
    if (filterActive === true) {
      setListingsActive(!listingsActive)
      setFilterActive(false)
    } else {
      setListingsActive(!listingsActive)
    }
  }

  const handleListingHoverHighlight = e => {
    //console.log('handleListingHoverHighlight')
    markers.map(marker => {
      return marker.profile === "full" &&
        marker.title === e.currentTarget.querySelector(".heading").innerText
        ? marker.marker.setIcon(MapMarkerPremiumHover)
        : marker.profile === "basic" &&
          marker.title === e.currentTarget.querySelector(".heading").innerText
        ? marker.marker.setIcon(MapMarkerHover)
        : ""
    })
  }

  const handleListingHoverHighlightClear = e => {
    //console.log('handleListingHoverHighlightClear')
    markers.map(marker => {
      return marker.profile === "full"
        ? marker.marker.setIcon(
            process.env.GATSBY_SITENAME === "Leeds"
              ? MapMarkerPremiumLeeds
              : process.env.GATSBY_SITENAME === "York"
              ? MapMarkerPremiumYork
              : process.env.GATSBY_SITENAME === "Manchester"
              ? MapMarkerPremiumManchester
              : ""
          )
        : marker.profile === "basic"
        ? marker.marker.setIcon(MapMarker)
        : ""
    })
  }

  const handleWhereChange = e => {
    setWhatState("*")
    filter1.current[0].selected = true
    //console.log('handleWhereChange')
    showResetButton()
    let newLatLng = new maps.LatLng(
      parseFloat(e.target[e.target.selectedIndex].getAttribute("lat")),
      parseFloat(e.target[e.target.selectedIndex].getAttribute("lng"))
    )

    map.setZoom(15)
    map.panTo(newLatLng)
  }

  const handleWhatChange = () => {
    const target = what

    markers.forEach(marker => {
      marker.marker.setVisible(false)
      if (marker.marker.category.what !== undefined) {
        if (
          marker.marker.category.what.includes(target) &&
          marker.marker.category.offer === true
        ) {
          marker.marker.setVisible(true)
        }
      }
    })
  }

  useEffect(() => {
    if (map !== null) {
      handleWhatChange()
    }
    console.log(whatState)
  }, [whatState, map])

  const setFilterState = target => {
    const queryString = window.location.search
    console.log(queryString)
    const urlParams = new URLSearchParams(queryString)
    const filter = urlParams.get("filter")

    if (filter === "entertainment-culture" && initialiseWhat === false) {
      setWhatState("Entertainment & Culture")
      filter1.current.selectedIndex = 1
      setInitialiseWhat(true)
    }

    if (filter === "food-drink" && initialiseWhat === false) {
      setWhatState("Food & Drink")
      filter1.current.selectedIndex = 2
      setInitialiseWhat(true)
    }

    if (filter === "shopping-lifestyle" && initialiseWhat === false) {
      setWhatState("Shopping & Lifestyle")
      filter1.current.selectedIndex = 3
      setInitialiseWhat(true)
    }
  }

  useEffect(() => {
    if (initialiseWhat === false) {
      setFilterState()
    }
  })

  // useEffect(() => {
  //   if (map !== null) {
  //     // console.log("offers", offers)
  //     console.log("showResetBtn", showResetBtn)
  //     // handleOffers()
  //   }
  // }, [offers])

  const noResults = () => {
    //console.log('noResults')
    if (allInboundListings !== null && allInboundListings.length === 0) {
      filterWrap.current.style.backgroundColor = "var(--red)"
      filterWrap.current.style.color = "var(--white)"
      filterWrap.current
        .querySelector(".filters--wrapper")
        .classList.add("error")

      const isClient = typeof document !== "undefined"

      if (isClient) {
        const errorWarning = document.querySelector(".errorwarning")
        const toggleWrap = document.querySelector(".toggleWrap")
        const listingsWrap = document.querySelector("#listings-scroller")
        toggleWrap.classList.add("error")
        listingsWrap.classList.add("error")

        if (errorWarning !== null && errorWarning !== undefined) {
          errorWarning.classList.add("error")
        }
      }
    } else {
      filterWrap.current.style.backgroundColor = "var(--brandColor)"
      filterWrap.current.style.color = "var(--black)"
      filterWrap.current
        .querySelector(".filters--wrapper")
        .classList.remove("error")

      const isClient = typeof document !== "undefined"

      if (isClient) {
        const errorWarning = document.querySelector(".errorwarning")
        const toggleWrap = document.querySelector(".toggleWrap")
        const listingsWrap = document.querySelector("#listings-scroller")
        toggleWrap.classList.remove("error")
        listingsWrap.classList.remove("error")

        if (errorWarning !== null && errorWarning !== undefined) {
          errorWarning.classList.remove("error")
        }
      }
    }
  }

  const resetFilters = () => {
    //console.log('resetFilters')
    // Get user position
    let newLatLng = new maps.LatLng(
      parseFloat(lat),
      parseFloat(lng)
      // parseFloat(userLat),
      // parseFloat(userLng)
    )
    //Recenter map to user position
    map.panTo(newLatLng)

    // Show all markers if they've been hidden
    markers.map(marker => {
      return marker.marker.setVisible(true)
    })

    //Reset state to show all Inbound listings
    setWhatState("*")

    map.setZoom(18)

    // Show clusters
    mapRef.current.classList.remove("hide")

    // reset filters to default
    filter1.current.selectedIndex = 0
    if (filter2.current !== undefined) {
      filter2.current.selectedIndex = 0
    }

    hideResetButton()
  }

  const showResetButton = () => {
    //console.log('showResetButton')
    setShowResetBtn(true)
  }

  const hideResetButton = () => {
    //console.log('hideResetButton')
    setShowResetBtn(false)
  }

  const getGeolocationPostion = () => {
    //console.log('getGeolocationPostion')

    navigator.geolocation.watchPosition(
      position => {
        setUserLat(position.coords.latitude)
        setUserLng(position.coords.longitude)
        setUserConsent(true)
        if (userPositionSet === false) {
          setUserPositionSet(true)
          setLat(userLat)
          setLng(userLng)
        }
        // this.setState({
        //   // lat: position.coords.latitude,
        //   // lng: position.coords.longitude,
        //   userLat: position.coords.latitude,
        //   userLng: position.coords.longitude,
        //   userConsent: true,
        // }, () => {
        //     if(this.state.userPositionSet === false){
        //         this.setState({
        //             userPositionSet: true,
        //             lat:this.state.userLat,
        //             lng:this.state.userLng
        //         })
        //     }
        // });
      }
      //   (err) => //console.log(err),
      //   { enableHighAccuracy: true, timeout: 10000, maximumAge: 10000 },
    )
  }

  const getInBoundListings = () => {
    //console.log('getInBoundListings')
    let allInboundListings = []
    let results = []

    if (whatState === "*") {
      results = allListings
    }

    if (whatState !== "*") {
      allListings.forEach(item => {
        item.listingsCategories.nodes.forEach(node => {
          if (node.name === whatState) {
            results.push(item)
          }
        })
      })
    }

    // Loop through all results
    results.forEach(listing => {
      if (listing.acf_listings.image1 === null) {
        ////console.log(listing.title + " has no image")
      }
      if (listing.acf_listings.latitude === null) {
        ////console.log(listing.title + " has no latitude")
      }
      if (listing.acf_listings.longitude === null) {
        ////console.log(listing.title + " has no longitude")
      }

      // If has lat/lng (to avoid errors with listing that don't have values)
      if (
        listing.acf_listings.latitude &&
        listing.acf_listings.longitude &&
        listing.acf_listings.image1
      ) {
        // If distance from user to listing is less than listingsRadius e.g 100m then push listing to state
        if (
          map.getBounds().contains({
            lat: parseFloat(listing.acf_listings.latitude),
            lng: parseFloat(listing.acf_listings.longitude),
          })
        ) {
          const whatArr = []
          listing.listingsCategories.nodes.map(item => {
            return whatArr.push(item.name)
          })
          // console.log("🤞", listing)
          allInboundListings.push({
            title: listing.title,
            lat: parseFloat(listing.acf_listings.latitude),
            lng: parseFloat(listing.acf_listings.longitude),
            img: listing.acf_listings.image1.localFile.childImageSharp
              .gatsbyImageData,
            tagline: listing.acf_listings.taglineSummary,
            offer: listing.acf_listings.blackCardPromotion,
            address: listing.acf_listings.address,
            slug: listing.slug,
            what: whatArr,
            profile: listing.reduceToBasicListing.profileType,
            listingRaw: listing,
          })
        }
      }
    })

    setAllInboundListings(allInboundListings)

    // this.setState({
    //     allInboundListings: allInboundListings,
    // }, () => {
    //     this.markers();

    // })
  }

  useEffect(() => {
    if (map !== null) {
      getInBoundListings()
      if (whatState !== "*" || whereState !== "*") {
        setShowResetBtn(true)
      } else {
        setShowResetBtn(false)
      }
    }
  }, [map, whatState, whereState])

  useEffect(() => {
    if (allInboundListings !== null) {
      markersFunc(markers)
    }
  }, [allInboundListings])

  const markersFunc = markers => {
    //console.log('markersFunc')
    let infowindows = []
    let clusterMarkers = []
    const isClient = typeof document !== "undefined"

    allInboundListings?.forEach((listing, i) => {
      new maps.LatLng(listing.lat, listing.lng)
      let marker
      let infowindow

      if (listing.profile === "full") {
        // Create Marker for all Premium Listings
        marker = new maps.Marker({
          position: listing,
          map: map,
          icon:
            process.env.GATSBY_SITENAME === "Leeds"
              ? MapMarkerPremiumLeeds
              : process.env.GATSBY_SITENAME === "York"
              ? MapMarkerPremiumYork
              : process.env.GATSBY_SITENAME === "Manchester"
              ? MapMarkerPremiumManchester
              : "",
          category: [listing.what, listing.offer],
          title: listing.title,
        })
      }

      if (listing.profile === "basic") {
        // Create Marker for all Basic Listings
        marker = new maps.Marker({
          position: listing,
          map: map,
          icon: MapMarker,
          category: [listing.what, listing.offer],
          title: listing.title,
        })
        clusterMarkers.push(marker)
      }

      // Add Click event to toggle visibility of Infowindow
      marker.addListener("click", e => {
        // Create Infowindow
        let infoContent
        if (isClient) {
          const content = document.querySelector(
            `.infowindow[data-listing='${listing.title}']`
          )
          infoContent = content.cloneNode(true)
        }
        infowindow = new maps.InfoWindow({
          content: infoContent,
        })

        setListingsWithActiveInfowindow(listing.slug)
        //console.log(listingsWithActiveInfowindow, listing.slug)

        if (isClient) {
          const oldMapElements = document.querySelectorAll(".gm-style-iw-a")

          oldMapElements.forEach(element => {
            element.parentNode.remove()
          })
        }

        infowindow.close()
        infowindow.open(map, marker)
        if (listing.profile === "full") {
          marker.setIcon(MapMarkerPremiumHover)
        }
        if (listing.profile === "basic") {
          marker.setIcon(MapMarkerHover)
        }

        setInfoWindowTitle(listing.title)
        setInfoWindowImg(listing.img)
        setInfoWindowTagline(listing.tagline)
        setInfoWindowOffer(listing.offer)
        setInfoWindowAddress(listing.address)
        setInfoWindowSlug(listing.slug)

        // this.setState({
        //     InfoWindowTitle:'test',
        //     InfoWindowImg:'test',
        //     InfoWindowTagline:'test',
        //     InfoWindowOffer:'test',
        //     InfoWindowAddress:'test',
        //     InfoWindowSlug:'test',
        // }, () => {
        //     this.setState({
        //         InfoWindowTitle:listing.title,
        //         InfoWindowImg:listing.img,
        //         InfoWindowTagline:listing.tagline,
        //         InfoWindowOffer:listing.offer,
        //         InfoWindowAddress:listing.address,
        //         InfoWindowSlug:listing.slug,
        //     })
        // })
      })

      if (listing.profile === "full") {
        maps.event.addListener(marker, "mouseover", e => {
          marker.setIcon(MapMarkerPremiumHover)
          Array.from(listings.current.querySelectorAll(".listing")).map(
            listing => {
              if (
                listing.querySelector(".heading").innerText === marker.title
              ) {
                listing.querySelector("article").classList.add("active")
              }
            }
          )
        })

        maps.event.addListener(marker, "mouseout", e => {
          marker.setIcon(
            process.env.GATSBY_SITENAME === "Leeds"
              ? MapMarkerPremiumLeeds
              : process.env.GATSBY_SITENAME === "York"
              ? MapMarkerPremiumYork
              : process.env.GATSBY_SITENAME === "Manchester"
              ? MapMarkerPremiumManchester
              : ""
          )
          Array.from(listings.current.querySelectorAll(".listing")).forEach(
            listing => {
              listing.querySelector("article").classList.remove("active")
            }
          )
        })
      }

      if (listing.profile === "basic") {
        maps.event.addListener(marker, "mouseover", e => {
          marker.setIcon(MapMarkerHover)
          Array.from(listings.current.querySelectorAll(".listing")).map(
            listing => {
              if (
                listing.querySelector(".heading").innerText === marker.title
              ) {
                listing.querySelector("article").classList.add("active")
              }
            }
          )
        })

        maps.event.addListener(marker, "mouseout", e => {
          marker.setIcon(MapMarker)
          Array.from(listings.current.querySelectorAll(".listing")).forEach(
            listing => {
              listing.querySelector("article").classList.remove("active")
            }
          )
        })
      }
      markers.push({
        marker: marker,
        title: listing.title,
        profile: listing.profile,
      })
    })

    var clusterStyles = [
      {
        textColor: "white",
        url: MapMarkerCluster,
        height: 30,
        width: 30,
      },
      {
        textColor: "white",
        url: MapMarkerClusterDarker,
        height: 30,
        width: 30,
      },
      {
        textColor: "white",
        url: MapMarkerClusterDarker,
        height: 30,
        width: 30,
      },
    ]

    // Create Marker Clusters
    new MarkerClusterer(map, clusterMarkers, {
      // imagePath: 'https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m',
      gridSize: 100,
      minimumClusterSize: 2,
      maxZoom: 17,
      styles: clusterStyles,
    })
  }

  const initMap = (mapSetter, mapsSetter) => {
    //console.log('initMap')
    setMap(mapSetter)
    setMaps(mapsSetter)
  }

  useEffect(() => {
    if (isClient && listingsWithActiveInfowindow !== null) {
      setTimeout(
        function () {
          const target = document.querySelector(
            `.listing[data-listing="${listingsWithActiveInfowindow}"]`
          )
          target.parentNode.parentNode.scrollTop = target.offsetTop
        }.bind(this),
        1000
      )
    }
  }, [listingsWithActiveInfowindow])

  useEffect(() => {
    noResults()
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    })
    // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
    let value = params.filter // "some_value"
    if (value !== null) {
      // setWhatState("Food & Drink")
    }
  })

  useEffect(() => {
    if (map !== null) {
      setMapBounds(map.getBounds())
      getInBoundListings()
    }

    if (maps !== null) {
      maps.event.addListener(
        map,
        "bounds_changed",
        function () {
          //console.log('bounds changed')
          setMapBounds(map.getBounds())
          getInBoundListings()
        }.bind(this)
      )

      maps.event.addListener(
        map,
        "center_changed",
        function () {
          setHasPanned(true)
          //console.log('center changed')
        }.bind(this)
      )

      setMapLoaded(true)
      // maps.event.addListener(map, 'idle', function(){
      // }.bind(this));
    }
  }, [map, maps])

  useEffect(() => {
    if (isClient) {
      setItems(allListings)
    }

    if (map !== null) {
      getGeolocationPostion()
    }
  }, [map])

  function mapOptions(maps) {
    return {
      zoomControlOptions: {
        position: maps.ControlPosition.LEFT_TOP,
        style: maps.ZoomControlStyle.SMALL,
      },
      scrollwheel: false,
      zoomControl: true,
      mapTypeControl: false,
      minZoom: 15,
      navigationControl: false,
      fullscreenControl: false,
      draggable: true,
      styles: mapStyles,
    }
  }

  return (
    <IndependentsMapAndFilter className="independents-map">
      <MapWrap
        className={mapLoaded === true ? "map loaded" : "map"}
        ref={mapRef}
      >
        <div className="map-loading">
          <span>Map loading</span>
          {/* <User className="beacon"/> */}
        </div>
        <div className="map-wrap-inner">
          {isClient && (
            <GoogleMapReact
              bootstrapURLKeys={{
                key: process.env.GATSBY_GOOGLEMAP_API,
                libraries: ["geometry"],
              }}
              center={[lat, lng]}
              defaultZoom={zoom}
              options={mapOptions}
              yesIWantToUseGoogleMapApiInternals
              // onGoogleApiLoaded={({ map, maps }) => //console.log(map, maps)}
              onGoogleApiLoaded={({ map, maps }) => initMap(map, maps)}
            >
              {
                // If user has Geolocation and accepts show them on the map
                userLat && userLng && userConsent === true ? (
                  <User lat={userLat} lng={userLng} />
                ) : (
                  ""
                )
              }
            </GoogleMapReact>
          )}
        </div>
      </MapWrap>
      <ToggleWrap className="toggleWrap">
        <div
          className={filterActive === true ? "active button" : "button"}
          onClick={e => toggleFilters(e)}
        >
          <GrSort />
          <span>{filterActive === true ? "Hide" : "Show"} </span> Filters
        </div>
        <div
          className={listingsActive === true ? "active button" : "button"}
          onClick={e => toggleListings(e)}
        >
          <IoIosContacts />
          <span>{listingsActive === true ? "Hide" : "Show"} </span> Listings
        </div>
      </ToggleWrap>
      <FilterWrap
        ref={filterWrap}
        className={filterActive === true ? "showFilters" : ""}
      >
        <div className="filters--wrapper">
          <div className="heading">Explore {area} Businesses</div>

          <button
            className={showResetBtn === true ? "clear" : "clear hidden"}
            onClick={resetFilters}
          >
            Reset <IoIosCloseCircleOutline />
          </button>

          <div
            className={`filters-inner-wrapper ${
              showLocationsFilter === true ? "" : "hide_locations"
            }`}
          >
            <div className="select-wrap filter-what">
              <select
                ref={filter1}
                defaultValue="What?"
                name="filter-what"
                id="filter-what"
                onChange={e => setWhatState(e.target.value)}
              >
                <option value="*">What?</option>
                {/* <option selected disabled>What?</option> */}
                {what?.nodes?.map((item, index) => {
                  return (
                    <option key={item.slug + "-" + index} value={item.name}>
                      {item.name}
                    </option>
                  )
                })}
              </select>
              <DD className="dd" />
            </div>
            {showLocationsFilter === true ? (
              <div className="select-wrap filter-where">
                <select
                  ref={filter2}
                  defaultValue="Where?"
                  name="filter-where"
                  id="filter-where"
                  onChange={e => handleWhereChange(e)}
                >
                  <option value="Where?" disabled>
                    Where?
                  </option>
                  {/* <option selected disabled>Where?</option> */}
                  {where?.nodes?.map((item, index) => {
                    return (
                      <option
                        key={item.slug + "-" + index}
                        lat={item.acf_listing_location_fields.lat}
                        lng={item.acf_listing_location_fields.lng}
                      >
                        {item.name}
                      </option>
                    )
                  })}
                </select>
                <DD className="dd" />
              </div>
            ) : (
              ""
            )}
            <Link to={"offers/"} className="offers">
              <span>Offers?</span>
            </Link>
          </div>
        </div>
      </FilterWrap>
      <ListingsWrap
        id="listings-scroller"
        className={
          listingsActive === true
            ? "showListings listings--wrapper"
            : "listings--wrapper"
        }
      >
        <ListingsContainer ref={listings}>
          {/* {console.log(allInboundListings)} */}
          {allInboundListings !== null ? (
            allInboundListings.length > 0 ? (
              allInboundListings.map((listing, index) => {
                return (
                  <ListingWrapper
                    key={listing.title + index}
                    onMouseEnter={e => handleListingHoverHighlight(e)}
                    onMouseLeave={e => handleListingHoverHighlightClear(e)}
                    className="listing"
                    data-listing={listing.slug}
                  >
                    <Listing
                      key={listing.title + index}
                      listing={listing}
                      customClass={
                        listingsWithActiveInfowindow === listing.slug
                          ? `is_active`
                          : ``
                      }
                    />

                    {listing.profile === "full" ? (
                      <InfoWindow
                        className="infowindow"
                        data-listing={listing.title}
                      >
                        <div className="image">
                          <GatsbyImage image={listing.img} />
                        </div>
                        <div className="info">
                          <h2 className="heading">{listing.title}</h2>
                          {listing.tagline ? (
                            <div className="tagline">{listing.tagline}</div>
                          ) : (
                            ""
                          )}
                          {listing.offer ? (
                            <div className="offer">{listing.offer}</div>
                          ) : (
                            ""
                          )}
                          <div className="address">{listing.address}</div>
                        </div>
                        <div className="btn-wrap">
                          <Button
                            className="viewButton"
                            color="black"
                            bg="black"
                            text={"View business"}
                            customClass={"map_listing_click_trigger_infowindow"}
                            link={getListingSlug(listing.listingRaw)}
                          />
                        </div>
                      </InfoWindow>
                    ) : listing.profile === "basic" ? (
                      <InfoWindow
                        className="infowindow"
                        data-listing={listing.title}
                      >
                        <div className="info">
                          <h2 className="heading">{listing.title}</h2>
                          <div className="address">{listing.address}</div>
                        </div>
                        <div className="btn-wrap">
                          <Button
                            className="viewButton"
                            color="black"
                            bg="black"
                            text={"View business"}
                            link={getListingSlug(listing.listingRaw)}
                          />
                        </div>
                      </InfoWindow>
                    ) : (
                      ""
                    )}
                  </ListingWrapper>
                )
              })
            ) : (
              <ErrorWarning className="errorwarning">
                Sorry there are no independents that match your criteria in this
                area. Try zooming out to find other nearby independents.
              </ErrorWarning>
            )
          ) : (
            ""
          )}
        </ListingsContainer>
      </ListingsWrap>
    </IndependentsMapAndFilter>
  )
}
