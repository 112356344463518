import React from "react"
import FeaturedBusiness from "../homepage/FeaturedBusiness"
import MapFiltersNew from "../independents/MapFiltersNew"
import GridCardSlider from "../global/GridCardSlider"
import Seo from "../seo"
import Editorial from "./Editorial"

export default function Areas(props) {
  const {
    offersState,
    basicListings,
    premiumListings,
    allListings,
    offerListings,
    what,
    where,
    map,
    data,
    seo,
    listingsData,
    area,
    areaImage,
    slider1Array,
    slider2Array,
    showLocationsFilter,
    editorial,
  } = props
  return (
    <>
      <Seo
        title={seo.title}
        description={seo.metaDesc}
        image={
          data.data.og.options.acf_options.ogIndependentsMapOffers.mediaItemUrl
        }
      />

      <MapFiltersNew
        offersState={offersState !== null ? offersState : false}
        basicListings={basicListings}
        premiumListings={premiumListings}
        allListings={allListings}
        offerListings={offerListings}
        what={what}
        where={where}
        mapProp={map}
        area={area}
        showLocationsFilter={showLocationsFilter}
      />

      <Editorial editorial={editorial} area={area} areaImage={areaImage} />

      <GridCardSlider
        sectionTitle={
          listingsData?.sliders1?.acf_independent_page_fields?.slider1Heading ||
          listingsData?.sliders1?.acf_areas?.slider1Heading
        }
        linkPrefix={"/tttttt/"}
        data={slider1Array}
        bgPosition="bottom"
        bgSize="100%"
        bgColor="white"
      />

      <FeaturedBusiness
        data={
          listingsData?.featuredBusiness?.acf_independent_page_fields
            ?.chooseFeaturedBusiness
        }
      />
      {listingsData?.sliders2.length > 0 ? (
        <GridCardSlider
          sectionTitle={
            listingsData?.sliders2?.acf_independent_page_fields
              ?.slider2Heading ||
            listingsData?.sliders2?.acf_areas?.slider2Heading
          }
          linkPrefix={"/tttttt/"}
          data={slider2Array}
          titleColor="white"
          arrowColor={"white"}
          bgPosition="top"
          bgSize="65%"
          bgColor="charcoal"
        />
      ) : (
        ""
      )}
      {/* {console.log(data)} */}
    </>
  )
}
